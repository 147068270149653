<template>
  <v-select
    v-model="status"
    :items="campos"
    clearable
    @click:clear="clearStatus"
    outlined
    dense
    item-text="tipo"
    item-value="id"
    hide-details
    label="Status"
  ></v-select>
</template>

<script>
export default {
  name: "Status",

  props: {
    campos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      status: null,
    };
  },

  watch: {
    status() {
      if (this.status || this.status === 0) {
        this.$store.commit("Filtros/UPDATE_STATUS", this.status);
      }
    },
  },

  methods: {
    clearStatus() {
      this.$store.commit("Filtros/UPDATE_STATUS", null);
      this.status = null;
    },
  },

  mounted() {
    if (this.$route.query.status || this.$route.query.status === 0) {
      let status = this.campos.find(
        (item) => item.id == this.$route.query.status
      );
      this.status = status.id;
    }
  },
};
</script>

<style></style>
